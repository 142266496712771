import { Flex, Text, AccordionButton, AccordionPanel, Accordion, AccordionItem } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { ReactNode } from 'react'

interface Props {
  title: string
  text: ReactNode
  textColor: string
  bgColor: string
  isExpanded: boolean
  buttonH: string
  borderRadius: string
  border: string
}

export interface Question {
  title: string
  bgColor: string
  textColor: string
  text: ReactNode
}

export const QuestionItem = ({
  title,
  text,
  textColor,
  bgColor,
  isExpanded,
  buttonH = '40px',
  borderRadius,
  border,
}: Props) => {
  return (
    <Flex direction="column" bg={bgColor} w="full" h="full" borderRadius={borderRadius} border={border}>
      <AccordionButton justifyContent="space-between" my={3} h={buttonH}>
        <Text color={textColor} textAlign="start">
          {title}
        </Text>
        {isExpanded ? <MinusIcon fontSize="12px" color={textColor} /> : <AddIcon fontSize="12px" color={textColor} />}
      </AccordionButton>
      <AccordionPanel>
        <Flex direction="column" rowGap={2} textColor={textColor}>
          {text}
        </Flex>
      </AccordionPanel>
    </Flex>
  )
}

export const AccordionFAQ = ({
  border,
  borderRadius,
  questions,
}: {
  border: string
  borderRadius: string
  questions: Question[]
}) => {
  return (
    <Accordion
      allowToggle
      display="flex"
      w={{ base: 'full', md: '800px' }}
      h="full"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection={{ base: 'column', md: 'row' }}
      columnGap={10}
      rowGap={10}
      flexWrap="wrap"
      px={6}
      mb={16}
    >
      {questions.map(({ title, bgColor, textColor, text }, i) => {
        return (
          <AccordionItem w={{ base: 'full', md: '600px' }} key={i} border="none">
            {({ isExpanded }) => (
              <>
                <QuestionItem
                  key={`${title}_${i}`}
                  title={title}
                  text={text}
                  textColor={textColor}
                  bgColor={bgColor}
                  isExpanded={isExpanded}
                  buttonH="40px"
                  border={border}
                  borderRadius={borderRadius}
                />
              </>
            )}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
